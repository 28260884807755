.App {
  text-align: center;
  font-family: "Cormorant Garamond", sans;
}

#menu-toggle:checked + #menu {
  display: block;
}

.darken {
  filter: brightness(60%);
}

.slide-text {
  animation: slideInVertically 2s ease-in-out;
}

@keyframes slideInVertically {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.carousel-feedback .carousel-control-next-icon,
.carousel-feedback .carousel-control-prev-icon {
  background-color: #968476;
}


